export default function Element(state='view'){ // ne doit pas être async ! (sinon ne peut pas être utilisé à l'ouevrture de guide avec l'actuel code)
    try{
        //on lit les cookie
        let cookie = Object.fromEntries(new URLSearchParams(document.cookie.replace(/; /g, "&")))
        console.log(cookie)
        

        //si il n'y a pas de cookie on en crée un
        if(!cookie.user){cookie.user = Math.ceil(Math.random()*1000000000)}
        if(!cookie.date){cookie.date = new Date()*1}
        //if(!Object.keys(cookie).includes('user')){document.cookie = 'user"="'+Math.ceil(Math.random()*1000000000)+'"; expires=3153600000; SameSite=lax'}
        //if(!Object.keys(cookie).includes('date')){document.cookie = '"date"="'+(new Date())*1+'"; expires=3153600000; SameSite=lax'}

        //on ajoute la page où on est actuellement (en lui donnant la valeur view/complete)
        cookie[window.location.pathname]=state

        //reset
        if(state=='reset'){
            console.log('resestation')
            for(let [key,value] of Object.entries(cookie)){
                if(!['user', 'date'].includes(key)){
                    console.log(key)
                    delete cookie[key]
                    document.cookie = key+"=;expires=Thu, 01 Jan 1970 00:00:00 GMT;"
                }
            }
        }

        //on enregistre notre objet cookie comme cookie du site
        for(let [key,value] of Object.entries(cookie)){
            document.cookie = key+"="+value
        }
        
        return cookie

    }catch(e){
        return {cookieFailed:true}
    }
}
