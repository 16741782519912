import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'


import intro from './3.jpg'
import imageSAP from './city.jpg'
import imageGlobal from './10.avif'
import imageOld from './6.avif'
import imageConnexion from './connexion.jpg'
import imagemConnexion from './modesConnexion.jpg'
import imageinfo from './informations.jpg'
import imagenova from './nova.jpg'
import imageimpot from './impotgouv.jpg'
import imageurssaf from './urssaf.jpg'
import imagemessagerie from './messagerie.jpg'


function Element(){
  const classes = ComponentArticle.useStyles()
  let localStyle={
    subTitle:{
      marginTop:'30px',
      fontWeight:'bold',
      fontSize:'1.2em',
      color:ComponentArticle.color.column1Dark
    }
  }
  
    return(<>
    <Helmet>
      <title>Habilitation API Tiers de prestation - Guide complet </title>
      <meta name="description" content="Guide complet Services à la Personne - Article dédié aux Services à la personne : Les 50% de crédit d'impôt, la condition d'exclusivité, ..."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Habilitation API Tiers de Prestation 
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark, marginTop:'20px',}}>
            Dernière mise à jour le 15 décembre 2024
      </div>


      <iframe 
        className={ComponentArticle.useStyles().video}
        style={{marginTop:'20px'}}
        src='https://www.youtube-nocookie.com/embed/xqoNcm8QTWw?rel=0'
        frameborder="0" 
        allowfullscreen>
      </iframe>





      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Sommaire
      </div>

      <div className={classes.text}>
      
      <a href='#avanceImmediate' style={{color:ComponentArticle.color.column1Dark, textDecoration:'none', 'margin-left':'40px', fontWeight:'bold',}}> 
        ❓ L'Avance Immédiate du crédit d'impôt
      </a>
      <div style={{fontSize:'0.85em'}}>
        <em>
          Augmentez de 30% vos revenus, avec le dispositif proposé par plus de la moitié des professsionnels
        </em>
      </div>

      <br/>
        <a href='#api' style={{color:ComponentArticle.color.column1Dark, textDecoration:'none', 'margin-left':'40px', fontWeight:'bold',}}> 
          🔍 Quelles différences entre l'API Tiers de prestation et Avance Immédiate ?
        </a>
        <div style={{fontSize:'0.85em'}}>
          <em> 
            2 noms pour un dispositif unique 
          </em>
        </div>
        
        <br/>
        <a href='#habilitation' style={{color:ComponentArticle.color.column1Dark, textDecoration:'none', 'margin-left':'40px', fontWeight:'bold',}}>
          🛠️ Réalisez votre habilitation à l'API Tiers de prestation
        </a>
        <div style={{fontSize:'0.85em'}}>
          <em>
            Notre guide pas à pas pour proposer l'Avance Immédiate simplement et rapidement
          </em>
        </div>
      </div>




      <div id='avanceImmediate'/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        L'Avance Immédiate du Crédit d'Impôt (AICI)
      </div>
      <img src={imageOld} style={{width:'70vw', maxWidth:'500px', marginBottom:'15px'}}/> 
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Lancée en 2022 par l'Urssaf, l'Avance Immédiate du Crédit d'Impôt permet aux particuliers de régler <strong>uniquement la moitié</strong> des prestations de Service à la Personne dont ils ont bénéficié !
        <br/>Ainsi la nouvelle génération du dispositif, met fin à l'avance des 50% de crédit d'impôt pendant 9 à 21 mois !
        <br/>
        <br/>
        Le dispositif d'AICI permet également de démocratiser les services à la personne au plus grand nombre, augmentant d'autant la quantité de services demandés, et laissant plus de liberté aux professionnels quant à la définition de leurs prix horaire.
        <br/>Le gouvernement et les différentes fédérations du SAP, estiment une <strong>croissance du secteur de l'ordre de 30%</strong>, induite seulement à la mise en place du dispositif !
        <br/>
        <br/>
        De plus en passant par le dispositif d'Avance Immédiate, c'est l'Urssaf qui prélève directement les particuliers avant de reverser le montant total (c'est à dire la partie réglée par le client, plus les 50% de crédit d'impôt) au professionnel.
        Réduisant ainsi les oublis et impayés, permettant aux professionnels de gagner de la trésorerie et d'économiser un temps précieu.
        <br/>
        <br/>
        Enfin voici comment proposer l'Avance Immédiate du Crédit d'Impôt en seulement trois étapes :
        <ul>
          <li> Obtenir votre habilitation à l'API Tiers de prestation ;</li>
          <li> Inscrire son client sur un logiciel compatible AICI, tel <a href='https://avance-immediate.fr/' rel='nofollow' target='_blank'>Avance Immédiate Services</a> ;</li>
          <li> Déclarer vos prestations sur ce même logiciel.</li>
        </ul>
      </div>


      <div id='api'/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        API Tiers de prestation ou Avance Immédiate ?
      </div>
      <img src={imageGlobal} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px'}}/>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Une API ('Application Programming Interface') est un terme technique, afin de désigner un service informatique sans interface, avec lequel il est possible de communiquer uniquement en passant par un logiciel compatible (pour déclarer vos prestations par exemple).
        <br/>
        <br/>
        Cela vous semble abstrait ? Pas de problème ! Retenez simplement que pour proposer l'Avance Immédiate, vous devez être habilité à l'API Tiers de prestation ET réaliser vos déclarations sur un logiciel compatible.
        <br/>
        <br/>
        Un de ces logiciels compatible est <a href='https://avance-immediate.fr/' rel='nofollow' target='_blank'><strong>Avance Immédiate Services</strong></a> : Solution complète, dédiée aux professionnels des Services à la Personne, elle vous permet de gérer l'ensemble de votre activité et de proposer l'Avance Immédiate simplement et efficacement.
        <br/>Il est également possible de conserver votre logiciel comptable actuel et d'utiliser Avance Immédiate Services afin de proposer l'Avance Immédiate du crédit d'impôt, grâce à ses nombreuses fonctionnalités exclusives.
        <br/>En plus d'une version gratuite, notre équipe d'experts vous accompagnent par mail, téléphone, ou visio, afin de répondre à l'ensemble de vos questions lors de votre habilitaiton ou prise en main du logiciel.
        <br/>
        <br/>
        Maintenant que nous avons vu que les professionnels doivent être habilités à l'API Tiers de prestation afin de proposer l'Avance Immédiate, nous allons voir comment réaliser cette démarche simplement et pas à pas !
        <br/>
        <br/>Notez que vous pouvez également confier la réalisation de votre habilitation à notre équipe d'expert, afin de s'assurer la qualité de votre dossier et accélerer ainsi votre habilitation. 
        <br/>De plus la première année de votre logiciel compatible Avance Immédiate est incluse :
      </div>
      <a className={classes.buttonTurquoise} href='https://avance-immediate.fr/ais2'>Faire réaliser mon habilitation par <br/>Avance Immédiate Services</a>
      

      




      <div id='habilitation'/>
      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Habilitation API Tiers de prestation, en 8 étapes simple
      </div>

      <div className={classes.text}>
        <div className={classes.subTitle} style={localStyle.subTitle}>
          Connexion
        </div>
        <br/>
        La première étape pour réaliser sa demande d'habilitation est de créer un compte sur le site <a href='https://www.demarches-simplifiees.fr' rel='nofollow' target='_blank'>demarches-simplifies.fr</a>. 
        Vous aurez alors plusieurs solutions possibles pour vous connecter. Soit à travers les services du gouvernement ou simplement à travers votre adresse mail :
      </div>
      <img src={imagemConnexion} style={{width:'70vw', maxWidth:'700px',marginBottom:'15px',marginTop:'20px'}}/>
      <div className={classes.text}>
        <br/>
        Une fois votre compte crée, vous pourrez créer votre demande d'habilitation à l'API Tiers de prestation en cliquant sur <a href='https://www.demarches-simplifiees.fr/commencer/api-tiers-de-prestations' target='_blank' rel='nofollow'>ce lien</a>. 
        <br/>
        <br/>
        Votre SIRET vous sera alors demandé afin de préremplir vos informations. Le site <a href='https://www.pappers.fr/' target='_blank' rel='nofollow'>Pappers.fr</a> vous permettra de rapidement le retrouver.
        <br/>Dans le cas où votre structure est soumis à une diffusion partielle de vos informations, vous pourrez les indiquer manuellement.
      </div>
    
      <div className={classes.text}>
        <div style={localStyle.subTitle} >
          1. Informations liées à l'entreprise
        </div>
      </div>
      <img src={imageinfo} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px',marginTop:'20px'}}/>
      <div className={classes.text}>
        Dans un premier temps il vous ai demandé de décrire votre projet. Cela permet à l'Urssaf de s'assurer que votre demande est cohérente avec votre projet, qu'il n'y a pas d'erreur sur le choix de l'API par exemple.
        <br/> 
        <br/>
        <strong>Nom du projet </strong> : 
        Vous pourrez indiquer ici le nom donné à votre projet pour la mise en place de l'Avance Immédiate.
        <br/>
        <em>Par exemple: "Proposer l'Avance Immédiate du crédit d'Impôt."</em><br />
        <br/>
        <strong> Description du projet </strong> :
        Nous allons pouvoir ici entrer dans le détail de notre activité.
        <br/><em>Par exemple : "Mon organisme propose des services de nettoyage aux particuliers dans les environs de Rennes. Je souhaiterais permettre à mes clients particuliers de bénéficier de l'Avance Immédiate du Crédit d'Impôt."</em><br />
        <br/>


        <div style={localStyle.subTitle}>
          2. Logiciel de facturation AICI 
        </div>
        <br/>Afin de communiquer avec l'API Tiers de prestation et ainsi inscrire vos clients et déclarer vos prestations en Avance Immédiate, il est nécessaire d'utiliser un logiciel habilité par l'Urssaf à l'API Tiers de prestation.
        En effet, le professionnel ET son logiciel doivent être habilités.
        <br/>
        <br/> 
        <a href='https://avance-immediate.fr/' rel='nofollow' taregt='_blank'>Avance Immédiate Services</a> est la solution de gestion dédiée aux Services à la Personne.
        <br/>Habilitée par l'Urssaf à l'API Tiers de prestation (compatible AICI), elle vous permet de concentrer toute votre activité sur une unique plateforme : Factures, Attestations fiscales, avance immédiate, synthèses Nova, ... 
        Mais vous pouvez également conserver votre logiciel actuel et utiliser AIS seulement pour l'Avance Immédiate !
        <br/>En plus de sa <strong>version Gratuite</strong>, nos experts vous accompagnent lors de votre habilitation et la prise en main de notre logiciel !
        <br/>
      </div>
      <a className={classes.buttonTurquoise} href='https://avance-immediate.fr/'>Découvrez la solution de gestion SAP <br/>Avance Immédiate Services</a>
      <div className={classes.text}>
        <br/>Si notre solution vous interesse, vous pouvez cocher "J'ai recours à un éditeur de logiciel", et indiquer simplement "Avance Immédiate Services". Aucune inscription au préalable n'est nécessaire !
      </div>

      <div className={classes.text}>
        <div style={localStyle.subTitle}>
          3. Numéro SAP
        </div>
      </div>
      <img src={imagenova} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px',marginTop:'20px'}}/>
      <div className={classes.text}>
        <br/>Votre structure a reçu un numéro SAP (pour "Service à la Personne") lors de votre agrément sur le site <a href='https://nova.entreprises.gouv.fr/' rel='nofollow' target='_blank'>Nova</a>.
        C'est cette déclaration qui vous permet de proposer les 50% de crédit d'impôt "classique" à vos clients.
        <br/>Si vous n'avez pas encore réalisé cette démarche, vous pourrez suivre notre <a href='/articleNova'>tutoriel dédié ici</a>.
        <br/>
        <br/>Dans le cas où vous n'avez pas encore réalisé cette démarche, vous pourrez retrouver votre numéro SAP sur votre espace Nova. Il s'agit des trois lettres "SAP" suivi de votre SIREN (9 chiffres).
        <br/>
        <br/>


        <div style={localStyle.subTitle}>
          4. Maquette du projet
        </div>
        <br/>L'Urssaf souhaite vérifier comment les professionnels communiquent à propos de l'Avance Immédiate, que les bons termes soient utilisés, que l'ensemble des informations soient indiquées, etc.
        <br/>
        <br/>Pour cela l'Urssaf a créé un kit de communication complet, <a href='https://www.urssaf.fr/portail/files/live/sites/urssaf/files/documents/SAP-Kits-communication-Avance-immediate.zip' target='_blank' rel='nofollow'>téléchargeable ici</a>.
        <br/>Vous pouvez également envoyer les documents ci-dessous. Avec plusieurs années d'expérience nous savons qu'ils permettent de valider simplement cette étape :
        <br/>
        <ul>
          <li>Un <a href='https://formation.avance-immediate.fr/Documents/habilitationMailType.odt'>mail type</a>, où vous n'aurez qu'à changer les trois champs surlignés en jaune, afin de correspondre à votre structure ;</li>
          <li>Une <a href='https://formation.avance-immediate.fr/Documents/habilitationInfographie.pdf'>infographie</a> présentant le fonctionnement des demandes de paiement ;</li>
          <li>Un <a href='https://formation.avance-immediate.fr/Documents/habilitationDepliant.pdf'>dépliant</a> récapitulatif. </li>
        </ul>
        <br/>


        <div style={localStyle.subTitle}>
          5. Date de mise en production prévue :
        </div>
        <br/>Vous pouvez simplement indiquer la date d'aujourd'hui.
        <br/>
        <br/>


        <div style={localStyle.subTitle}>
          6. Attestation de régularité fiscale
        </div>
        <br/>Dans le cas où vous êtes accompagné par un expert comptable, vous pourrez vous en rapprocher afin d'obtenir ce document.
        <br/>
        <br/>Autrement, vous pourrez soit consulter notre <a href='https://avance-immediate.fr/Documents/guideAttestation.pdf'>guide détaillé</a> ou en fonction de votre statut, obtenir ce document sur les sites suivants :
        <br/>
        <ul>
          <li>Dans votre <strong> espace professionnel Urssaf</strong> (<a href='https://www.urssaf.fr/accueil/se-connecter.html' rel='nofollow' target='_blank'>urssaf.fr</a>), onglet "Documents" / "Attestations" ;          </li>
        </ul>
      </div>
      <img src={imageurssaf} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px',marginTop:'20px'}}/>
      <div className={classes.text}>
        <ul>
          <li>Dans <strong>votre espace professionnel </strong> <a href='https://www.impots.gouv.fr/accueil' rel='nofollow' target='_blank'> impots.gouv.fr</a>, onglet "Consulter" / "Compte Fiscal" / "Attestations fiscales" ;</li>
        </ul>
      </div>
      <img src={imageimpot} style={{width:'70vw', maxWidth:'500px',marginBottom:'15px',marginTop:'20px'}}/>
      <div className={classes.text}>
        <ul>
          <li>Si aucune de ces options ne vous a permis de récupérer ce document, vous pourrez soit contacter leur services grâce aux <strong>mesageries sécurisées</strong> incluses dans ces deux plateformes, ou compléter et envoyer le <a href='https://www.impots.gouv.fr/formulaire/3666-sd/attestation-de-regularite-fiscale' target='_blank' rel='nofollow'><strong>Cerfa 3666</strong></a>.
          <br/>Veuillez dans ce cas bien compléter les deux pages du CERFA. Il est possible qu'il vous soit nécessaire de vous rendre aux guichets de votre centre des impôts afin qu'ils complètent la partie qu'il leurs ai dédié.</li>
        </ul>


        <div style={localStyle.subTitle}>
          7. Coordonnées bancaires 
        </div>
        <br/>Le <strong>compte de virement</strong> correspond au compte qui percevra votre chiffre d'affaires en avance immédiate. 
        C'est-à-dire les 50% du montant prélevés à votre client, majorés des 50% de crédit d'impôt.
        <br/>

        <br/>Le <strong>Compte de prélèvement</strong> lui, correspond au compte qui sera prélevé en cas de non paiement de la part de vos clients :
        <br/>Dans le cas où un de vos clients n'a pas les fonds suffisants pour régler votre prestation, l'Urssaf vous enverra dans un premier temps la totalité du montant de votre facture.
        <br/>Ce n'est que plus tard que l'Urssaf vous reprélèvera des 50% qui n'ont pas été réglé par votre client.
        <br/>Vous assurant ainsi de toujours percevoir au moins la moitié du montant, votre client devant dans ce cas vous régler directement les 50% qui lui incombent.
        <br/>
        <br/>Si vous n'indiquez pas de compte de prélèvement (la solution majoritaire adoptée), l'Urssaf utilisera les mêmes coordonnées que le compte de virement
        <br/>
        <br/>


        <div style={localStyle.subTitle}>
          8. Informations prestataires et contact
        </div>
        <br/>
        <br/><strong>Raison sociale du prestataire</strong> : correspond au nom administratif de votre structure (Nom prénom pour les auto-entreprises).
        <br/>Vous pourrez le vérifier en recherchant votre Siren sur le site <a href='https://www.pappers.fr/' rel='nofollow' target='_blank'> pappers.fr</a>
        <br/>
        <br/>

        <strong>Nom commercial</strong> : Identique à votre raison sociale, sauf si vous avez indiqué un nom commercial lors de la création de votre structure.
        Egalement vérifiable sur le site Pappers.fr (oui nous apprécions leur travail !), il s'agit du nom entre parenthèse.
        <br/>
        <br/>
        

        <strong>Téléphone, mail et adresse postale</strong> : Ces informations seront visibles par vos clients dans leurs espaces particuliers Urssaf. Veuillez à donc bien renseigner vos coordonnées professionnelles.
        <br/>
        <br/>
        

        <strong>Contacts</strong> : Quelle que soit la taille de votre structure, vous pouvez indiquer ici la même personne pour le contact de gestion, comptable et technique.
        <br/>
        <br/>Veuillez vous assurer que vous avez bien accès aux contacts indiqués : 
        <br/>Le responsable de gestion recevra les demandes de pièces complémentaires si besoin, et le responsable technique vos clés API Tiers de prestation lors de validation de votre habilitation.
        <br/>
        <br/>Vous pouvez à présent accepter les CGU et déposer votre demande d'habilitation.
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Conclusion
      </div>
      <div className={classes.text}>
        L'Urssaf va maintenant considérer votre dossier, et reviendra vers vous par mail si son étude nécessite des documents complémentaires.
        <br/>Une fois votre dossier validé, vous recevrez vos deux clés API Tiers de prestation par mail. Vous pourrez alors les renseigner dans votre logiciel compatible et proposer l'Avance Immédiate du crédit d'impôt à vos clients ! 
        <br/>
        <br/>
        Comme toujours, si vous avez la moindre question, nous restons à votre disposition !
        <br/>N'hésitez pas à prendre contact avec <a href='https://avance-immediate.fr/Contact'>nos experts ici</a>.
      </div>





      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>

      
      
      <Commentary page={document.location}/>

    </div>
    </div>



    </>)
}

export default Element