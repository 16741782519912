import { useState, useLayoutEffect } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import axios from "axios";
//import networkInterfaces from 'os-browserify'

import { makeStyles } from "@mui/styles"

//import { GiHamburgerMenu } from "react-icons/gi"
//import { AiOutlineClose } from "react-icons/ai"
import BurgerOpen from "@mui/icons-material/Menu";
import BurgerClose from "@mui/icons-material/Close";

import logo from "./logo2.png";
import Promo from "./logo.png"

const styleParameter = {
  turquoise: "rgba(0,170,190)",
  blue: "rgba(50,100,140)",
  blueLight:'#00DDF7',
  green: "rgb(0, 189, 165)",
  //limitSize: "850px",
};
const useStyles = makeStyles({
  main:{
    width:'100%',
    position: "fixed",
    zIndex: "10",
    //border:'2px solid red'
  },
  promo:{
    //width:'100%',
    padding:'0px 5vw 0px 0vw',

    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    
    textAlign:'center',
    
    //background:styleParameter.blue,
    //background:'#00AABE',
    background:'linear-gradient(45deg, rgba(0,170,190),rgba(0,221,247,0.5))',
    //background:'linear-gradient(145deg, rgba(40,80,100), 20%, rgba(50,100,140), 80%, #00AABE)', 
    color:'white',
    fontWeight:'bold',
    cursor:'initial',
    textDecoration:'none',
    
    transition: "ease 0.5s",

  },
  promoPhoto:{
    height:'100px',
    "@media (max-width: 850px)": {
      marginLeft:'-130px',
    }
  },
  promoText:{
    fontSize:'1.4em',
    "@media (max-width: 850px)": {
      fontSize:'1.15em',
    }
  },
  promoCounter:{
    "@media (max-width: 850px)": {
      display:'none',
    }
  },
  promoImportant:{
    padding:'0px 5px 2px 5px',
    lineHeight:'30px',
    fontSize:'1.1em',
    background:'pink',
    color:'white',
    cursor:'pointer',
    borderRadius:'5px',
    "&:hover": {
      //color: "blue",
    },
    "@media (max-width: 850px)": {
      fontSize:'1em',
    }

  },
  top:{
    padding:'10px 10px 10px 10px',
    minHeight:'40px',

    display:'flex',
    alignItems:'center',
    justifyContent:'center',

    textAlign:'center',
    fontWeight:'bold',
    color:'white',
    //background:styleParameter.blue,
    //background:styleParameter.green,
    background:styleParameter.turquoise,
    //background:'rgba(250,150,250)',
    //border:'2px solid',
    transition: "ease 0.5s",
  
  },
  topLink:{
    textDecoration:'none',
    color:'white',
  },
  topButton:{
    padding:'6px 10px 6px 10px',
    //background:'white',
    borderRadius:'10px',
    color:'white',
    //background:"rgba(0,170,190)",
    //background:"rgba(255,255,255,0.5)",
    background:styleParameter.blueLight,
    //background:"linear-gradient(135deg,rgba(0,170,190) 20%, rgba(50,100,140) 65%)",
    //filter: 'brightness(1.5)',
    
    boxShadow: "0px 1px 4px grey",
    
    
  },
  bottom: {
    width: "100%",
    height: "100px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    transition: "ease 0.5s",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "16px",

    background: "#FDFDFF",
    //border:'2px solid black',
    //background:'linear-gradient(135deg,rgba(0,170,190) 20%, rgba(50,100,140) 65%, rgba(250,150,0), rgba(0,170,190) 80%)',
  },
  bottomBorder: {
    boxShadow: "0px 1px 10px lightblue",
    transition: "ease 0.5s",
  },

  logo: {
    height: "15vw",
    maxHeight: "80px",
    marginLeft: "3vw",
    zIndex:1,
    
    //animation: "$enterOpacity 1s",
    //border:'2px solid black',
  },

  "@keyframes menu": {
    "0%": { height: "0" },
    "100%": { height: "320px" },
  },
  menu: {
    marginRight: "20px",
    marginLeft: "20px",
    height: "50px",
    padding: "20px 0px",
    
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    gap: "30px",
    //transition: "height 4s ease-in-out;",
    //border:'2px solid red',

    "@media (max-width: 1200px)": {
      position: "fixed", //absolute
      top: "100px",
      //left:'-35px',
      marginRight: "0px", //annule le paramètres grand écran
      marginLeft: "0vw", //annule le paramètres grand écran
      paddingTop: "30px",
      minWidth: "none", //annule le paramètres grand écran
      maxWidth: "none", //annule le paramètres grand écran
      width: "100%",
      height: "450px",

      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",

      background: "white",
      animation: "$menu 0.75s",

      //border:'2px solid black'
    },
  },

  "@keyframes text": {
    "0%": { opacity: "0" },
    "33%": { opacity: "0" },
    "100%": { opacity: "1" },
  },

  link: {
    height: "50px",
    minWidth: "120px",
    
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.5s", //duration transition normal->hoover
    //border:'2px solid black',
    
    "&:visited": {
      color: "inherit", //annule le changement de couleur si lien visité
    },
    "&:hover": {
      marginTop: "-5px",
    },
  },
  text: {
    fontWeight: "bold",
    textAlign: "center",
    color: styleParameter.turquoise,
    "&:hover": {
      color: styleParameter.blue,  
    },
    "@media (max-width: 850px)": {
      width: "250px",
      paddingTop: "20px",
      paddingBottom: "20px",

      color: styleParameter.turquoise, //"#264f71",
      animation: "$text 2s",
    },
  },


  "@keyframes app": {
    "0%": { backgroundPosition: "0% 0%" },
    "100%": { backgroundPosition: "100% 100%" },
  },
  app: {
    padding: "0px 10px 0px 10px",
    borderRadius: "15px",
    boxShadow: "0px 1px 4px grey",
    //textShadow:'1px 1px 1px grey',
    //background:'linear-gradient(135deg,'+styleParameter.color+','+styleParameter.color2+')',
    //background:'linear-gradient(135deg,rgba(0,170,190) 20%, rgba(50,100,140) 33%, rgba(250,150,0), rgba(0,170,190) 80%)',
    background:
      "linear-gradient(135deg,rgba(0,170,190) 20%, rgba(50,100,140) 65%, rgba(250,150,0), rgba(0,170,190) 80%)",
    backgroundSize: "500% 500%",
    color: "white",
    animation: "$app 10s infinite",

    "@media (max-width: 850px)": {
      borderRadius: "0px",
      animation: "$app 10s infinite, $text 2s",
    },
  },

  burgerButton: {
    position: "absolute",
    right: "5vw",

    //height: "100px",
    //Width: "100px",
    //marginRight: "30px",
    color: styleParameter.turquoise, //"black",
    cursor: "pointer",

    //border:'2px solid black',

    "&:hover": {
      filter: "brightness(1.2)",
      color: styleParameter.blue,
    },
  },



  ressourceMain:{
    padding:'40px 5vw 40px 5vw',
    display:'none', //commence caché
    flexDirection:'row',
    justifyContent:'space-between',

    background:'white',
    //border:'0.5px solid',    
    
    transition:'0.5s ease-out',
  },
  ressourceColumn:{
    width:'25vw',
    //border:'2px solid',
  },
  ressourceTitle:{
    //marginLeft:'20px',
    marginBottom:'20px',
    color: styleParameter.turquoise,
    fontWeight:'bold',
    fontSize:'1.35em',
  },
  ressourceLine:{
    lineHeight:'1.7em',
    fontSize:'1.1em',
    color:'rgba(0,100,120)',
    textDecoration:'none',
    "&:hover":{
      color:styleParameter.turquoise,
      cursor:'pointer',
    },
    "@media (max-width: 850px)": {
      fontSize: "0.8em",
    },
  },
  ressourceTransparent:{
    //border:'2px solid',
    display:'none', //commecne caché
    width:'100vw',
    height:'100vh', //pour cacher lors de l'ouverture de la page    
  }
});

function Component(props) {
  const classes = useStyles(); /*import scc style created with makeStyles @mui*/

  //variables
  const [display, setDisplay] = useState(false)
  const [ressource, setRessource] = useState(false)
  const [scrollDisplay, setScrollDisplay] = useState(true)
  const [counter, setCounter] = useState(new Date(2024,9,15)-new Date())

  async function changeRessource(ressourceChosen=ressource){
    if(ressourceChosen){
      await document.querySelectorAll('[id=ressource]').forEach(element=>{
        element.style.opacity='0'
      })
      await new Promise(resolve=>setTimeout(resolve,500))
      await document.querySelectorAll('[id=ressource]').forEach(element=>{element.style.display='none'})
      setDisplay(false) //en version smartphone, permet de tout fermer d'un coup
    }else{
      await document.querySelectorAll('[id=ressource]').forEach(element=>{element.style.display='flex'})
      await new Promise(resolve=>setTimeout(resolve,1)) //permet de orendre en compte display, pour changer opacity et ainsi prendre en compte transition (durée animation)
      await document.querySelectorAll('[id=ressource]').forEach(element=>{
        element.style.opacity='1'
      }) 
    }
    setRessource(!ressourceChosen)
  }

  //_______________________________________________________handle aparition
  useLayoutEffect(() => {
    //scroll
    function scrollCheck() {
      if (window.scrollY > 100) {setScrollDisplay(true)} //window.scrollY < 0.9* window.innerHeight
     else {setScrollDisplay(false)}
    }
    window.addEventListener("scroll", scrollCheck);
    scrollCheck();

    //manage menu show when window width change
    function resizeCheck(){
      if(window.innerWidth<1200){
        setDisplay(false)
        setRessource(false)
      }
      else{setDisplay(true)}
    }
    window.addEventListener("resize", resizeCheck);
    

    /*async function changeCounter(){
      await setCounter(new Date(2024,9,15)-new Date())
    }
    setInterval(changeCounter,1000) //setInterval est une fonction par defaut de react
    */
  }, []);


  //_________________________________________________________changePage
  let { id } = useParams()
  async function urlParameterParser(parameter){
    parameter = parameter.replaceAll('?','')
    parameter = parameter.replaceAll('=','":"')
    parameter = parameter.replaceAll('&','","')
    parameter = '{"'+parameter+'"}'
    parameter = JSON.parse(parameter)
    console.log(parameter)
    return parameter
  }
  //facebook ads convertion
  /*function fbq(f,b,e,v,n,t,s){
    console.log('fb convertion')
    if(f.fbq)return;
    console.log(1)
    n=f.fbq=function(){
      n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)
    }
    console.log(2)
    if(!f._fbq)f._fbq=n;
    console.log(3)
    n.push=n;
    n.loaded=!0;
    n.version='2.0';
    n.queue=[];
    t=b.createElement(e);
    t.async=!0;
    t.src=v;
    s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)
  }*/

  //google Ads (balise directe)
  /*function gtag_report_conversion(conversion) {
    let conversionList={
      application2:'AW-10930288623/hGzvCJjA0-AZEO_v-9so',
      contact:'AW-10930288623/hGzvCJjA0-AZEO_v-9so',
    }
    var callback = function () {
      let url = window.location//'https://app.avance-immediate.fr/'
      if (typeof(url) != 'undefined') {
        window.location = url
      }
    }
    console.log('convertion', conversion, conversionList[conversion])
    window.dataLayer.push('event', 'conversion', {
        'send_to': conversionList[conversion],
        'value': 1.0,
        'currency': 'EUR',
        'event_callback': callback
    })
  }*/

  async function changePage(conversion=false) {
    try{
      //record
      let url ="https://data.mongodb-api.com/app/mainapi-bvcjy/endpoint/websiteEndpoint"
      let body = {
        requestType: "write",
        collection: id != undefined ? id : "visit",
        licence: undefined,
        appVersion:4,
      };
      if(!!props.urlParameter){body = Object.assign({}, body, await urlParameterParser(props.urlParameter))}
      let response = await axios.post(url, body).then((response) => {
        if(response.data.boolean) {console.log("OK Redirect. App")}
        else {console.log(response.data)}
      });
    }catch(e){console.log(e)}

    //facebookAds
    //fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'})
    
    //in any case
    //if(!!conversion){
    //  await gtag_report_conversion(conversion) //google Ads
    //  window.location = "https://app.avance-immediate.fr/creer-un-compte/"+props.urlParameter
    //}else{window.location = "https://www.avance-immediate-services.fr/"+props.urlParameter}
  }

  //html showed
  return (
  <div style={{paddingBottom:'100px'}}> {/*pour décaler les autres fichiers*/}
  <div className={classes.main}>

    <div className={clsx(classes.top, scrollDisplay ? {display:'none'} : {})}>
      <a className={clsx(classes.topLink, scrollDisplay ? classes.promoOut : {})} href='https://avance-immediate.fr/ais2' id='googleTagAis+'>
        <span className={classes.topButton}>Découvrez AIS+</span> = habilitation Avance immédiate + Intégration + Solution de facturation. Pour seulement 99€ !
      </a>
    </div>
    
    <div className={clsx(classes.bottom, scrollDisplay ? classes.bottomBorder : {})}>
      <a href={"/"+props.urlParameter} id='test'>
        <img className={classes.logo} src={logo} alt="AIS" id='test'/>
      </a>

      {window.innerWidth<1200 &&
        <>{display ?
          <BurgerClose className={classes.burgerButton} sx={{ fontSize: 30 }} onClick={() => {setDisplay(!display); changeRessource(true)}}/>
          :<BurgerOpen className={classes.burgerButton} sx={{ fontSize: 30 }} onClick={() => setDisplay(!display)}/>
        }</>
      }

      {(window.innerWidth>1200 || (display && !ressource)) &&
        <div className={classes.menu} style={{marginTop:(window.innerWidth<1200?'60px':'0px')}}>
          <a className={classes.link} href={"/"} id='googleTagContact'>
            <div className={classes.text} id='googleTagContact'>
              Formations
            </div>
          </a>

          <a className={classes.link} href={"/Partenaires"} id='googleTagContact'>
            <div className={classes.text} id='googleTagContact'>
              Partenaires
            </div>
          </a>

          <a className={classes.link} href='https://avance-immediate.fr/Contact'>
            <div className={classes.text} id='googleTagContact'>
              Contact
            </div>
          </a>

          <a className={clsx(classes.link, classes.app)} id='googleTagApplication' href='https://avance-immediate.fr/redirection/formation'>
            <div className={classes.text} style={{ color: "white" }} id='googleTagApplication'>
              Solution de gestion SAP
            </div>
          </a>
        </div>}
      </div>
  </div>
  </div>
)}

export default Component
