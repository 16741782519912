import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    mainColumn:{
        marginTop:'40px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
    },
    main:{
        width:'80vw',
        maxWidth:'900px',

        display:'flex',
        flexDirection:'column',
        alignItems:'center',

        //border:'2px solid',
    },
    mainTitle:{
        fontSize:'2.5em',
        fontWeight:'bold',
        color:'orange',
    },
    mainDetail:{
        //marginTop:'20px',
        width:'100%',
        fontSize:'0.9em',
        fontWeight:'bold',
        //border:'2px solid',
    },
    title:{
        width:'90%',
        marginTop:'60px',
        marginBottom:'20px',
        fontSize:'1.4em',
        fontWeight:'bold',
    },
    subTitle:{
        fontWeight:'bold',
        //fontSize:'1.2em',

    },
    text:{
        width:'100%',
        color:'rgba(80,80,80)',
        lineHeight:'1.5em',
    },
    textImportant:{
        fontWeight:'bold',
    },
    image:{
        //border:'2px solid',
        //textAlign:'center',
    },
    imageDescription:{
        color:'rgba(150,150,150)',
        fontStyle:'italic',
        marginTop:'5px',
        textAlign:'end',

    },
    tableau:{
        width:'100%',
        marginTop:'40px',

        display:'flex',
        flexDirection:'row',
        justifyContent:'space-around',
        "@media (max-width: 1100px)": {
            flexDirection:'column',
        },
    },
    tableauColumn:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',

    },
    tableauImage:{
        width:'7vw',
        minWidth:'70px',
        maxWidth:'150px',
        marginBottom:'10px',
        //background:'blue',
        //filter:'saturate(10)',
        
    },  
    tableauTexte:{
        fontWeight:'bold',
        fontSize:'1.25em',
        textAlign:'center',
    },
    papper:{
        padding:'5px',
        background:'rgba(200,220,250)',
        borderRadius:'5px',
        cursor:'pointer',
        color:'white',
        fontWeight:'bold',
        textAlign:'center',
        border:'2px solid white',
    },
    bulle:{
        width:'70%',
        marginTop:'40px',
        padding:'30px',
        background:'rgba(200,220,250)',
        borderRadius:'0px 10px 50px 25px',
        color:'white',
        transition:'0.4s ease-in',
    },
    button:{
        marginTop:'50px',
        marginBottom:'100px',
        padding:'10px 20px 10px 20px',

        background:'orange',
        borderRadius:'10px',
        cursor:'pointer',

        color:'white',
        fontWeight:'bold',
        fontSize:'1.1em',
        textAlign:'center',
        textDecoration:'none',

    },
    buttonTurquoise:{
        marginTop:'20px',
        padding:'10px 20px 10px 20px',

        background:'linear-gradient(135deg,rgba(0,170,190) 20%, rgba(50,100,140))',
        borderRadius:'10px',
        cursor:'pointer',

        color:'white',
        fontWeight:'bold',
        fontSize:'1.1em',
        textAlign:'center',
        textDecoration:'none',

        transition:'1s',
        "&:hover": {
            //marginTop,
            filter:'brightness(1.3)'
          },
    },

    "@keyframes animated": {
        "0%": { opacity:'0',},
        "100%": { opacity:'1',},
      },
      animated:{
          animation:'$animated 1s'
      },
      video:{
        //marginTop:'30px',
        width:'80vw',
        maxWidth:'800px',
        height:'45vw',
        maxHeight:'450px',
        border:'2px solid white',
        borderRadius:'20px',
      },
})

let color={
    column2Light:'rgba(0,250,250)',
    column1Dark:'rgba(100,100,200)',
    column1Light:'rgba(210,210,250)',
    
}
let text={
    button:'Retourner au Menu'
}


export default {useStyles:useStyles, color:color, text:text}