import clsx from "clsx"
import { makeStyles, StylesContext } from "@mui/styles"
import { useState, useEffect, useLayoutEffect } from "react"
import {Helmet} from 'react-helmet'

import Style from '../Component/component'
import cookieComponent from '../Component/cookieComponent'

const useStyles = makeStyles({
    main:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        overflow:'hidden',
    },
    mainTitle:{
        marginTop:'50px',
        color:Style.color.main2,
        fontSize:'2vw',
        textAlign:'center',
        "@media (max-width: 1400px)": {
            fontSize: "2em",
          },
    },


    tableau:{
        marginTop:'50px',
        width:'100vw',
        
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',

        //border:'2px solid',
        "@media (max-width: 400px)": {
            flexDirection:'column',
        },
    },

    column:{
        width:'27vw',
        maxWidth:'400px',
        margin:'0px 2.5vw 0px 2.5vw',
        padding:'30px 0px 25px 0px',
        boxShadow:'2px 2px 8px 3px rgba(200,200,200)',
        transition:'1s ease-out',
        
        //border:'2px solid',
        borderRadius:'1vw',
        "@media (max-width: 400px)": { //same than tableau
            width: "80vw",
            marginLeft:'10vw',
            marginBottom:'20px',
          },
    },

    title:{
        width:'100%',
        marginBottom:'20px',
        textAlign:'center',

        color:'white',
        fontWeight:'bold',
        fontSize:'1.3em',
        //border:'2px solid',
    },

    "@keyframes enter": {
        "0%": {fontSize:'0px', padding:'0px',},
        "15%": {fontSize:'0px', padding:'0px',},
    },
    chapter:{
        height:'auto',
        padding:'10px',
        textAlign:'center',
        color:'rgba(110,110,110)',

        cursor:'pointer',
        //transition:'background 1s ease-out',
        "&:hover": {
            background:'grey',
            fontWeight:'bold',
        },
        //animation: "$enter 2.5s ease-out",
    },


    buttonBloc:{
        marginTop:'50px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',

        //border:'2px solid'
        "@media (max-width: 700px)": {
            flexDirection: "column",
          },
    },
    button:{
        width:'230px',
        margin:'0px 4vw 0vw 4vw',
        padding:'6px 3px 8px 3px',
        
        textAlign:'center',
        //color:'rgba(100,150,100)',
        color:Style.color.main2,
        fontWeight:'bold',
        cursor:'pointer',
        borderRadius:'7px',
        border:'2px solid rgba(200,200,200)',
        transition:'0.5s ease-in',
        "&:hover": {
            padding:'3px 0px 5px 0px',
            //border:'4px solid rgba(100,150,100)',
            fontSize:'1.05em',
            fontWeight:'bold',
            color:'white',
            border:'4px solid '+Style.color.main2,
            background:Style.color.main2, //rgba(250,180,0)
              
        },
    },

    
    contactBloc:{
        margin:'80px 0px 50px 0px',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
    },
    contactText:{
        fontWeight:'bold',
        color:Style.color.main2,
    },
})


const tableau={
    creation:{
        title:'Création', 
        color1:'rgba(200,200,250)', //rgba(200,100,100)
        color2:'rgba(50,100,220)', //pink
        complete:false,
        completeLogo:'🥇',
        content:[
            {title:'Les Services à la Personne', link:'/articleSap'},
            {title:'Quelle forme juridique ?', link:'/articleFormeJuridique'},
            {title:'Créer son auto-entreprise',},
            //{title:'Créer sa société', complete:false},
            {title:'Agrément Nova/SAP', link:'/articleNova'},
            {title:'Habilitation API TP', link:'/articleHabilitation'},
        ]
    },
    developpement:{
        title:'Se Développer', 
        color1:'rgba(250,200,250)',//'rgba(200,100,100)', //rgba(200,200,250) 
        color2:'rgba(250,100,200)', //'pink', //rgba(100,100,200) 
        complete:false,
        completeLogo:'🎯',
        content:[
            {title:'Avance Immédiate',link:'/articleAvanceImmediate'},
            {title:'Définir son offre commerciale', link:'/articleOffreCommerciale'},
            {title:'Trouver ses clients', link:'/articleTrouverClient'},
            {title:'Logiciels de gestion' ,link:'/articleOutil'},
            {title:'Recrutement', link:'/articleRecrutement'},
        ]
    },
    perfection:{
        title:'Comptabilité', 
        color1:'rgba(200,250,250)', //'green', 
        color2:'rgba(100,220,200)',//'rgba(0,210,230)', //'rgba(100,200,100)', 
        complete:false,
        completeLogo:'🏆',
        content:[
            {title:'Déclarations', link:'/articleDeclaration'},
            {title:'Taxes et Impôts', link:'/articleImpot'},
            {title:'Banques', link:'/articleBanque'},
            {title:'Assurances', link:'/articleAssurance'},
            {title:'Mutuelles Santé et Retraite', },
        ]
    },
}


function Component(){
    const classes = useStyles()
    const [progression, setProgression] = useState(0)
    let cookie = cookieComponent()
    

    function resetCookie(){
        cookieComponent('reset') //RAZ
        //window.location.reload() //refresh
    }

    function refreshProgression(){
        let numberTotal=0
        let numberComplete=0
        for(let column of Object.keys(tableau)){
            for(let line of tableau[column].content){
                if(!!line.link){numberTotal++} //on compte seulement si a un link
                if(Object.keys(cookie).includes(line.link)){numberComplete++}
            }
        }
        let percent = (numberComplete/numberTotal*100).toFixed(0)
        setProgression(percent)
        document.getElementById('progression').style.background='linear-gradient(90deg, '+Style.color.sub3+' '+percent+'%, white '+(percent*1+5)+'%)'
        if(percent==100){
            document.getElementById('progression').innerHTML='Obtenez votre diplôme ! 🎉'    
            document.getElementById('progression').style.background=Style.color.main2   
            document.getElementById('progression').style.color='white'
            document.cookie = 'certification=true; expires=3153600000; SameSite=lax'   
            
        }
    }
    
    
    function refreshChapterEnded(){
        // on vérifie pour chaque colonne si toute ses lignes sont réalisées
        for(let column of Object.keys(tableau)){
            let chapterEnded = true
            for(let i of Object.keys(tableau[column].content)){
                let line = tableau[column].content[i]
                if(!Object.keys(cookie).includes(line.link) && !!line.link){chapterEnded=false}
            }
            //si c'est le cas on l'ajoute aux cookies
            if(chapterEnded){
                document.cookie = tableau[column].title+'=true; expires=3153600000; SameSite=lax'
            }
        }
    }

    useLayoutEffect(() => {
        //RAZ
        let tableauElement = document.getElementById('tableau')
        tableauElement.innerHTML = ''
        //cookie = cookieComponent()
        console.log('cookie :', cookie)
        for(let column of Object.keys(tableau)){
            let columnComplete = Object.keys(cookie).includes(tableau[column].title)
            //ajout column
            let columnElement = document.createElement('div')
            columnElement.className=classes.column
            columnElement.style.background=columnComplete?tableau[column].color1:tableau[column].color2
            columnElement.style.border=columnComplete?'5px solid '+tableau[column].color2 : ''
            columnElement.id=tableau[column].title
            tableauElement.appendChild(columnElement)
            //ajout title
            let titleElement = document.createElement('div')
            titleElement.className=classes.title
            titleElement.innerHTML=tableau[column].title + (columnComplete?tableau[column].completeLogo:'')
            titleElement.style.color=columnComplete?tableau[column].color2:''
            columnElement.appendChild(titleElement)
            //ajout line
            for(let line of tableau[column].content){
                let lineComplete = !!cookie[line.link]
                let lineElement = document.createElement('div')
                lineElement.className=classes.chapter
                lineElement.innerHTML=line.title + (!line.link?' 🚧':'') + (lineComplete?' ✔️':'')
                lineElement.id=line.title
                lineElement.style.background=lineComplete?tableau[column].color1:'white'
                lineElement.style.color=lineComplete?tableau[column].color2:''//'white':''
                lineElement.addEventListener('mouseover', ()=>{if(!lineComplete){lineElement.style.color=tableau[column].color2}})
                lineElement.addEventListener('mouseout', ()=>{if(!lineComplete){lineElement.style.color=''}})
                if(!!line.link){lineElement.onclick=()=>{window.location = line.link}}
                columnElement.appendChild(lineElement)
            }
        }
        //refresh progression (on le gère avec des eventListener)
        document.getElementById('progression').addEventListener('mouseenter', ()=>{document.getElementById('progression').style.background=Style.color.main2})
        document.getElementById('progression').addEventListener('mouseleave', refreshProgression)        
    })

    //let cookie
    useEffect(()=>{
        //on vérifie si chaque chapitre est terminé
        refreshProgression()
        refreshChapterEnded()
    },[]) //le tableau vide en second argument, permet de lancer une seule fois useEffect !!
    
    return(<>

        <div className={classes.main}>        
            <div className={classes.mainTitle}>
                <span style={{color:Style.color.main2, fontWeight:'bold'}}>Formation complète du professionnel</span>
                <br/><span style={{color:Style.color.main2, fontWeight:'bold'}}>des Services à la Personne</span>
            </div>

            <div className={classes.tableau} id='tableau'/>

            <div className={classes.buttonBloc}>
                <div className={clsx(classes.button, classes.buttonProgression)} id='progression' onClick={()=>{cookieComponent(true); if(progression==100){window.location='/articleCertification'}}}>🎓 Certificat : {progression} %</div>
                <div className={clsx(classes.button, classes.buttonReinitialiser)} onClick={()=>resetCookie()}>⚡️ RÉINITIALISER</div>
            </div>

            <div className={classes.contactBloc}>
                <div className={classes.contactText}>Si vous souhaitez des articles sur d'autres thématiques, ou devenir partenaire :</div>
                <a className={(Style.style()).button} style={{marginLeft:'15px'}} href='https://avance-immediate.fr//Contact'>Nous Contacter</a>
            </div>
        </div>
        
    </>)
}

export default Component