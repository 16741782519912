import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; //BrowserRouter
import { useState, useEffect, useLayoutEffect } from "react"

import Header from './Component/header'
import Footer from './Component/footer'
import Popup from './Component/popup'

import Presentation from "./presentation/presentation";
import Guide from "./guide/guide";
import ArticleSap from "./guide/articleSap/articleSap";
import ArticleFormeJuridique from "./guide/articleFormeJuridique/articleFormeJuridique";
import ArticleAvanceImmediate from "./guide/articleAvanceImmediate/articleAvanceImmediate";
import ArticleHabilitation from "./guide/articleHabilitation/articleHabilitation";
import ArticleBanque from "./guide/articleBanque/articleBanque";
import ArticleAssurance from "./guide/articleAssurance/articleAssurance";
import ArticleOutil from "./guide/articleOutil/articleOutil";
import ArticleTrouverClient from "./guide/articleTrouverClient/articleTrouverClient";
import ArticleNova from "./guide/articleNova/articleNova";
import ArticleDeclaration from "./guide/articleDeclaration/articleDeclaration";
import ArticleImpot from "./guide/articleImpot/articleImpot";
import ArticleOffreCommerciale from "./guide/articleOffreCommerciale/articleOffreCommerciale";
import ArticleRecrutement from "./guide/articleRecrutement/articleRecrutement";
import ArticleCertification from "./guide/articleCertification/articleCertification";

import Contact from "./contact/contact";
import Partenaire from "./partenaire/partenaire";
import MentionLegale from "./mentionLegale/mentionLegale";

function App() {
  const [popup, setPopup] = useState(false)
  async function changePopup(state=true){
    await setPopup(state)
  }
  //{["*", "/", "/home", "/home/:id"].map((path, index) => <Route path={path} element={<Presentation/>} key={index} />)}
  
  return (
    <div>
      {popup && <Popup changePopup={changePopup}/>}
      <Header changePopup={changePopup}/>
      <Router>
        <Routes>
          <Route 
            path="*"
            element={<Guide/>}
          />
          <Route 
            path="presentation"
            element={<Presentation changePopup={changePopup}/>}
          />
          <Route 
            path="/guide" 
            element={<Guide/>} 
          />
          <Route 
            path="/barometre" 
            element={<Presentation/>} 
          />
          <Route 
            path="/contact" 
            element={<Contact/>} 
          />
          <Route 
            path="/partenaires" 
            element={<Partenaire/>} 
          />
          <Route 
            path="/mentionLegale" 
            element={<MentionLegale/>} 
          />

          <Route path="/articleSap" element={<ArticleSap/>}/>
          <Route path="/articleFormeJuridique" element={<ArticleFormeJuridique/>}/>
          <Route path="/articleNova" element={<ArticleNova/>}/>
          <Route path="/articleHabilitation" element={<ArticleHabilitation/>}/>
          <Route path="/articleAvanceImmediate" element={<ArticleAvanceImmediate changePopup={changePopup}/>}/>
          <Route path="/articleTrouverClient" element={<ArticleTrouverClient changePopup={changePopup}/>}/>
          <Route path="/articleOffreCommerciale" element={<ArticleOffreCommerciale changePopup={changePopup}/>}/>
          <Route path="/articleOutil" element={<ArticleOutil/>}/>
          <Route path="/articleRecrutement" element={<ArticleRecrutement/>}/>
          <Route path="/articleDeclaration" element={<ArticleDeclaration/>}/>
          <Route path="/articleImpot" element={<ArticleImpot/>}/>
          <Route path="/articleBanque" element={<ArticleBanque/>}/>
          <Route path="/articleAssurance" element={<ArticleAssurance/>}/>
          <Route path="/articleCertification" element={<ArticleCertification/>}/>
          

        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
