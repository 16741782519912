import {Helmet} from 'react-helmet'
import { useState, useEffect, useLayoutEffect } from "react"

import Component from '../../Component/component'
import ComponentArticle from '../componentArticle'
import Commentary from '../../Component/commentary'
import ContactAIS from './contactAIS'


import avanceImmediate from './avanceImmediate2.jpg'
import fonctionnement from './fonctionnement.webp'
import ais from './ais.jpg'
import dessin from './dance.jpg'


function Element(props){
  const classes = ComponentArticle.useStyles()
  const [inscription, setInscription] = useState(0)
  
    return(<>
    <Helmet>
      <title>Avance Immédiate du Crédit d'Impôt</title>
      <meta name="description" content="Comment proposer le crédit d'impôt immédiat et être habilité API Tiers de prestation. Notre guide dédié à l'API de Urssaf et aux services à la personne."/>
    </Helmet>

    <div className={classes.mainColumn}>
    <div className={classes.main}>
      <h1 className={classes.mainTitle}>
        Avance Immédiate du Crédit d'Impôt
      </h1>
      <div className={classes.mainDetail} style={{color:ComponentArticle.color.column1Dark}}>
            Dernière mise à jour le 15 décembre 2024
      </div>
      
      

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}}>
        Introduction
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        Lancée le 14 juin 2022 par l'Urssaf et la Direction Générale des Finances Publiques (DGFIP),
        <br/>L'Avance Immédiate du Crédit d'Impôt, anciennement nommée Home+, permet aux particuliers de bénéficier instantanément des 50% de crédit d'impôt.
        <br/>Ils réglent donc uniquement la moitié du montant de vos prestations ! Et n'aurons plus à avancer la moitié du montant des prestations perçues, jusqu'à leur prochaine déclaration fiscale (soit entre 6 et 18 mois).
      </div>
      <img className={classes.image} src={avanceImmediate} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',}} alt="Commmunication de l'Urssaf pour le lancement de l'Avance Immédiate."/>
      <div className={classes.text}>
        <br/><span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Sommaire</span>
        <br/><span style={{color:'white'}}> .......... </span><a href='#1'>Un dispositif extrêmement avantageux également pour les professionels</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#3'>Inscription des particuliers</a>
        <br/><span style={{color:'white'}}> .......... </span><a href='#4'>Déclaration de vos prestations</a>
      </div>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='1'>
        Les avantages pour les professionels
      </div>
      <div className={classes.text}>
        Les avantages de l'Avance Immédiate pour les particuliers sont évidents, mais le dispositif est également extrêmement interessant pour les professionnels :
        <ul>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Augmentation du volume d'activité : </span> 
            <br/>Grâce à un coût perçu moindre, vos actuels et nouveaux clients pourront augmenter sensiblement la quantité de services dont ils pourront bénéficier.
            <br/>Aussi, les foyers qui auparavant ne pouvaient avancer les 50% de crédit d'impôt, viennent ainsi augmenter le nombre de clients potentiels.
          </li><br/>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Revalorisation des marges : </span> 
            <br/>Toujours grâce à la diminution du coût perçu, le secteur du SAP profite d'une augmentation générale de ses tarifs horaires (voir notre <a style={{textDecoration:'underline', cursor:'pointer'}} onClick={props.changePopup}>Baromètre</a>).
            <br/>Permettant de palier en partie l'inflation aussi subie coté professionnel, et les difficultés chroniques de recrutement que connait notre secteur.
          </li><br/>
          <li>
            <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>Diminution des impayés : </span> 
            <br/>Comme nous le verrons dans la section suivante, les prestations déclarées en Avance Immédiate sont prélevées directement aux particuliers par l'Urssaf.
            <br/>Réduisant d'autant les oublis, relances et impayés : Vous permettant de vous concentrer sur votre coeur d'activité, améliorant votre trésorerie et vous économisant du temps précieux.
          </li>
        </ul>
        <br/>
        En bref, l'Avance Immédiate du Crédit d'Impôt est l'<span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>argument massue</span> dans un environnement concurrentiel, vous permettant de gagner de nouveaux clients et de fidéliser les anciens.
        <br/>
        <br/>C'est pourquoi aujourd'hui (décembre 2024), c'est déjà plus de la <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>moitié des professionnels</span> qui proposent ce dispositif à leurs clients.
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='2'>
        Fonctionnement
      </div>
      <div className={classes.text}>
        <span style={{color:'white'}}> .......... </span>
        La mise en place de l'Avance Immédiate peut être décrite en 3 étapes simples :
        <br/>
        <span style={{fontWeight:'bold', color:ComponentArticle.color.column1Dark}}>
          <br/>1. L'habilitation du professionnel à l'API Tiers de prestation (<a href='/articleHabilitation'>article dédié ici</a>);
          <br/>2. L'inscription du particulier à l'Avance Immédiate ;
          <br/>3. La déclaration de vos prestations en Avance Immédiate.
        </span>
        <br/>
        <br/>
      </div>
      <ContactAIS/>


      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='3'>
        Inscription des particuliers à l'Avance Immédiate
      </div>
      <iframe 
        className={ComponentArticle.useStyles().video}
        src={inscription==1?'https://www.youtube.com/embed/l4w0gjzzv-w':'https://www.youtube.com/embed/4xd7QOgics0'}
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <div className={classes.tableau} style={{marginTop:'25px'}}>
        <div className={Component.style().button} style={inscription==1?{background:'rgba(200,200,200)'}:{}} onClick={()=>{setInscription(0)}}>Inscription par le particulier</div>
        <div className={Component.style().button} style={inscription==0?{background:'rgba(200,200,200)'}:{}} onClick={()=>{setInscription(1)}}>Inscription par le professionel</div>
      </div>
      
      <div className={classes.text}>
        <br/>
        <span style={{color:'white'}}> .......... </span>
        L'inscription du particulier à l'Avance Immédiate doit être réalisé sur une plateforme habilitée par l'Urssaf (comme notre solution de gestion complète ou complémentaire <a href='https://avance-immediate.fr/' rel='nofloow' target='_blank'>Avance Immédiate Services</a>, qui nous servira d'exemple par la suite).
        <br/>
        L'inscription doit être réalisée par le professionnel ou le particulier sur cette même plateforme.
        <br/>
        <br/>
        L'Urssaf demande plusieurs informations afin de pouvoir retrouver le particulier dans la base des impôts (et ainsi demander à la DGFIP directement les 50% de crédit d'impôt), ainsi que le moyen de règlement du particulier.
        Les informations demandées sont ainsi :
        <ul>
          <li>Identité : Prénoms, nom d'usage et de naissance, date et lieu de naissance ;</li>
          <li>Contact : Adresse mail (valide), numéro de téléphone portable ;</li>
          <li>Adresse de résidence ;</li>
          <li>Coordonnées bancaires (le compte qui sera prélevé par l'Urssaf de la moitié du montant des prestations).</li>
        </ul>
        Une fois inscrit, le particulier recevra un mail de l'Urssaf où il devra valider la mise en relation avec le professionnel.
        <strong> Il s'agit de la seule action obligatoire de sa part</strong>.
        <br/>
        <br/>Les particuliers sans adresse mail ou téléphone portable pourront être inscrit avec les coordonnées d'un proche, voire du professionnel (avec son accord ecrit). 
        <br/>Vous pourrez découvrir en détail les interfaces coté particuliers sur <a href='https://avance-immediate.fr/Client' rel='nofollow' target='_blank'>cet article</a>.
      </div>

      <div className={classes.title} style={{color:ComponentArticle.color.column1Dark}} id='4'>
        Déclaration de vos prestations en Avance Immédiate
      </div> 
      <iframe 
        className={ComponentArticle.useStyles().video}
        src='https://www.youtube.com/embed/4D2qQzo8ZhI'
        frameborder="0" 
        allowfullscreen>
      </iframe>
      <div className={classes.text}>
        <br/>
        <span style={{color:'white'}}> .......... </span>
        Une fois votre client inscrit et vos prestations réalisées, vous pourrez déclarer vos prestations en Avance Immédiate, afin que vos clients bénéficient du dispositif fiscal.
        <br/>
        <br/>Comme pour l'inscription de vos clients, cette étape doit être réalisée sur un logiciel habilité à l'API Tiers de prestation.
        <br/>En plus de générer vos factures, attestations fiscales, synthèses Nova, le logiciel de gestion SAP Avance Immédiate Services vous permet également d'y déclarer vos prestations en Avance Immédiate.
        <br/>
        <br/>La déclaration de vos prestations reprend donc les principales informations de vos prestations : date et numéro de facture, montant unitaire, quantité et type d'activité.
        <br/>
        <br/>Une fois votre prestation déclarée :
        <ul>
          <li>Votre client est notifié par l'Urssaf du montant et du nom de professionnel (par mail ou sms en fonction de ses préférences) ;</li>
          <li>Il a alors deux jours ouvrés pour valider ou contester cette demande de paiement, la demande est automatiquement validée passé ce délai. 
            <br/>Votre client est alors directement prélevé par l'Urssaf de 50% du montant de vos prestations ;</li>
          <li>L'Urssaf rajoute les 50% de crédit d'impôt avant de vous renvoyer le montant total sur votre compte bancaire sous 4 jours ouvrés.</li>
        </ul> 
        Vous êtes donc réglé entre 4 et 6 jours ouvrés après la déclaration de vos prestations !
        <br/>
        <br/>Ainsi il n'y a donc plus de flux financier direct entre vous et votre client. Le prélèvement direct permet d'éviter les oublis, et diminue sensiblement les relances et impayés.
        <br/>
        <br/>Le client règle donc uniquement la moitié du montant de vos prestations !
      </div>
      <img className={classes.image} src={fonctionnement} style={{width:'80vw', maxWidth:'700px', marginTop:'0px',}} alt="Description fonctionnement déclaration en Avance Immédiate du ccrédit d'impôt."/>
      <div className={classes.text}>
        <br/>Les conditions restent identiques au crédit d'impôt classique : 
        <br/>Un particulier bénéficie de 50% de crédit d'impôt sur les premiers 12 000€ de prestation annuelle. 
        <br/>
        <br/>Vous voilà maintenant des fins connaisseurs de l'Avance Immédiate du Crédit d'Impôts !
      </div>      

      {false &&<img className={classes.image} src={dessin} style={{width:'80vw', maxWidth:'700px', marginTop:'30px', marginBottom:'30px',}} alt=" "/>}
      <a className={Component.style().button} href='/guide' style={{margin:'40px 0px 0px 0px'}}>
        {ComponentArticle.text.button}
      </a>
      <Commentary page={document.location}/>


    </div>
    </div>



    </>)
}

export default Element